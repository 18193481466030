import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MembershipForm from '../components/Sections/MembershipForm'
//import MembersForOwnership from '../components/Sections/MembersForOwnership'
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer, Benefit } from '../components/Blocks'
import MembershipInquiry from '../components/Forms/MembershipInquiry'
import { Hero, HeroVideo } from '../components/Sections'
import { Container, Row, Col, Table } from 'reactstrap'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Membership" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" x={50} y={50} height={null} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/"  posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC Cooperative Membership</h1>
              <p>Our Coorporative is an autonomous and duly registered association of persons, with a common bond of interest, who have voluntarily joined together to achieve their social, economic, and cultural needs</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <Hero src="app/cooperative.jpg" size={12} height={500} x={70} />
    <BlockContainer center={true} padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-5">SIDC Cooperative Principles</h1>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(1)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Voluntary and Open Membership</h4>
                    <div className="text-justify">
                      <p>Cooperatives are voluntary organizations, open to all persons able to use their services and willing to accept the responsibilities of membership, without gender, social, racial, cultural, political or religious discrimination.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(2)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Democrative Member Control </h4>
                    <div className="text-justify">
                      <p>Cooperatives are democratic organizations that are controlled by their members who actively participate in setting their policies and making decisions. Men and women serving as elected representatives, directors or officers are accountable to the membership. In primary cooperatives, members have equal voting rights of one-member, one-vote. Cooperatives at other levels are organized in the same democratic manner.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(3)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Member Economic Participation </h4>
                    <div className="text-justify">
                      <p>Members contribute equitably to, and democratically control, the capital of their cooperatives. At least part of that capital is the common property of the cooperative.</p>
                      <p>They shall receive limited compensation or limited interest, if any, on capital subscribed and paid as a condition of membership. Members allocate surpluses for any or all of the  following purposes: developing the cooperative by setting up reserves, part of which should at least be indivisible; benefitting members in proportion to their partonage of the cooperative's business; and, supporting other activities approved by the membership.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(4)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Autonomy and Independence</h4>
                    <div className="text-justify">
                      <p>Cooperatives are autonomous, self-help organizations controlled by their members. If they enter into aggreements with other organizations, including government, or raise capital from external sources, they shall do so on terms that ensure democratic control of their members and maintain their cooperative autonomy.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(5)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Education, Training and Information</h4>
                    <div className="text-justify">
                      <p>Cooperatives shall provide education and training for their members, elected and appointed representatives, managers, and employees, so that they can contribute effectively and efficiently to the development of their cooperatives.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(6)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Cooperation Among Cooperatives</h4>
                    <div className="text-justify">
                      <p>Cooperatives serve their members most effectively and strengthen the cooperative movement by working together through local, national, regional and international structures.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <h4 className="text-center text-md-right">(7)</h4>
                </Col>
                <Col md={{ size: 10}}>
                  <div className="text-center text-md-left">
                    <h4>Concern for Community</h4>
                    <div className="text-justify">
                      <p>Cooperatives work for the sustainable development of their communities through policies approved by their members.</p>
                    </div>
                  </div>
                </Col>
              </Row>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
          <h1 className="mb-3">Advantages of Cooperative</h1>
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Cooperative</th>
              <th>Other Businesses</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Main purpose is to provide service to members and communities</td>
              <td>Main purpose is to make profit for investors</td>
            </tr>
            <tr>
              <td>Union of persons</td>
              <td> Union of capital</td>
            </tr>
            <tr>
              <td>Control is democratic</td>
              <td>Control is based on the number of voting shares held</td>
            </tr>
            <tr>
              <td>Ownership is in the hands of members</td>
              <td>Ownership is in the hands of investors from other community, province or country</td>
            </tr>
            <tr>
              <td>Surplus is distributed to members in proportion to patronage</td>
              <td>Surplus is allocated in proportion to voting shares owned</td>
            </tr>
            <tr>
              <td>Shares are held in the members only and not traded for speculation</td>
              <td>Shares may be freely traded and fluctuate in value</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </BlockContainer>
    <MembershipForm/>
    {/*<MembersForOwnership/>*/}
    <MembershipInquiry />
    <BlockContainer center={false}>
      <Container>
        <Row>
          <Col sm={{ order: 2 }} md={{size: 6, order: 1 }}>
            <div className="mr-auto">
              <img className="img-fluid d-block" src={getAssetUrl('app/flow.png')} alt="SIDC CYCLE" />
            </div>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2 }} className="px-md-5 p-3 d-flex flex-column align-items-start justify-content-center">
              <h1>SIDC Cycle</h1>
              <div className="mb-3 lead text-justify">
                <p>And yet I feel that I never was a greater artist than now. When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface.</p>
              </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col lg="3" md="6">
              <Benefit src="app/Small-Farmer-Aid.png" iconClass="fa fa-circle fa-3x" title="">SIDC is a key driver for enabling small farmers to take advantage of the economic opportunities associated with them.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Economic-Gain.png" iconClass="fa fa-stop-circle-o fa-3x" title="">Farmers are able to reap more economic gains than they would have if they earned as individual producers.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Contract-Growing-Availer.png" iconClass="fa fa-stop-circle fa-3x" title="">Members can be one of the paiwi system availers, the contract-growing project of SIDC that has brought means of livelihood to members by giving them the opportunity to raise hogs. </Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Financial-Assistance.png" iconClass="fa fa-circle-o fa-3x" title="">SIDC gives assistance to its members who wanted to put up a SIDC Feeds outlet by providing them with credit line that will assist them in their livelihood.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Financial-Assistance.png" iconClass="fa fa-circle-o fa-3x" title="">SIDC though its KooPinoy SIDC Savings and Loans venture provides farmers with financial assistance to sustain their financial capacity.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Trainings-and-Seminar.png" iconClass="fa fa-stop-circle fa-3x" title="">SIDC provides trainings and financial literacy programs to farmers for their continuous enterprise development.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Agri-Inputs.png" iconClass="fa fa-stop-circle-o fa-3x" title="">SIDC provides agri-inputs to farmers who wanted to increase their agricultural production outputs. Koop Likas organic fertilizer is one on the inputs available.</Benefit>
          </Col>
          <Col lg="3" md="6">
              <Benefit src="app/Technical-Assistance.png" iconClass="fa fa-circle fa-3x" title="">SIDC provides technical assistance to farmers and producers so they may understand the use of new technology and farming methods.</Benefit>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC Products and Services</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 1 }} lg={{ size: 5, order: 1 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/SIDC-Agriculture-Farmer.jpg')}`} />
          </Col>
          <Col sm={{ order: 2 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">SIDC: 55 years of helping farmers</p>
            <div className="mb-3 text-justify">
              <p>For 55 years, the Sorosoro Ibaba Development Cooperative (SIDC) — one of the largest agri-based cooperatives in the country, has been helping farmers and its members to be successfully empowered by increasing their participation in the agricultural value chain and enhancing their access to more lucrative and dynamic markets through collective action.</p>
            </div>
            <div className="pt-0"> <a href={process.env.GATSBY_SITE_AGRICULTURE} className="btn btn-light border-primary">Read more</a> </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 2 }} lg={{ size: 7, order: 1 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">Financial Services</p>
            <div className="mb-3 text-justify">
              <p>Here at KooPinoy, we offer a wide range of custom-made financial support and assistance to fulfill your financial requirements.</p>
              <p>Based in the City of Batangas, under the umbrella of Soro-Soro Ibaba Development Cooperative (SIDC) our main aspiration is to provide easy, hassle-free, customizeable loan service, where our members' needs are put first.</p>
              <p>We accept time deposit, regular savings deposits, and savings for young ones. In return, comparably high interest rates were offered to account holders, both regular and associate members.</p>
            </div>
            <div className="pt-0"> <a href={process.env.GATSBY_SITE_KOOPINOY} className="btn btn-light border-primary">Read more</a> </div>
          </Col>
          <Col sm={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/KooPinoy-Cover-Photo.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/Mart.jpg')}`} />
          </Col>
          <Col sm={{ size: 12, order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">SIDC CONSUMER RETAILING</p>
            <div className="mb-3 text-justify">
              <p>Meet the needs of convenience oriented customers by providing a broad selection of high quality products and services at everyday fair prices, speedy transactions in a clean, safe and friendly environment.</p>
              <p>Provides a broad selection of high quality products and services at everyday fair prices</p>
              <p>Big Savings - Patronage Refund - Loyalty Program - Promotion Access - Grow with us</p>
              <p>Provides a broad selection of high quality products and services at everyday fair prices</p>
            </div>
            <div className="pt-0"> <a href={process.env.GATSBY_SITE_CONSUMER} className="btn btn-light border-primary">Read more</a> </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    {/*<BlockContainer padding={1} center={false}>
      <Container>
        <Row>
          <Col sm={{ size: 12, order: 2 }} lg={{ size: 7, order: 1 }} className="d-flex flex-column justify-content-center p-3 border-primary">
            <p className="lead mb-0 text-uppercase">Eco Tourism and Teambuilding</p>
            <div className="mb-3 text-justify">
              <p>Sorosoro Springs Resort and Adventure Park is mountain resort situated in Batangas City, a 2-hectare recreational venue where you can experience the a relaxing and unique ambiance ideal for business, leisure and family accommodations amidst the rolling hills and green landscape on this part of Ala-Eh province.</p>
              <p>This mountain resort offers arrays of facilities best for location meetings, events reunions and weddings. The scenic and appealing environment is a great spots to explore and to linger.</p>
            </div>
            <div className="pt-0"> <a href={process.env.GATSBY_SITE_ECO} className="btn btn-light border-primary">Read more</a> </div>
          </Col>
          <Col sm={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }} className="p-0">
            <img className="img-fluid d-block py-5" alt="SIDC PRODUCTS" src={`${getAssetUrl('app/SoroSoro-Springs-Pools-01.jpg')}`} />
          </Col>
        </Row>
      </Container>
    </BlockContainer> */}
  </Layout>
)

export default IndexPage
