import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { ListGroup, ListGroupItem, Container, Row, Col, Form, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup, Button } from 'reactstrap'

class MembershipInquiry extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            dropdownOpenInfoRequest: false,
            infoRequest: null,
            firstName: '',
            middleName: '',
            lastName: '',
            addressStreet: '',
            addressState: '',
            addressCity: '',
            addressPostalCode: '',
            addressProvince: '',
            phoneNumber: '',
            addressCountry: '',
            emailAddress: '',
            note: ''
        }
        this.benefits = [
            { iconClass: 'fa fa-cloud', name: 'Free Medical Checkup' },
            { iconClass: 'fa fa-cloud', name: 'SIDC Care' },
            { iconClass: 'fa fa-cloud', name: 'Scholarship Grant' },
            { iconClass: 'fa fa-cloud', name: 'Study Now - Pay Later' },
            { iconClass: 'fa fa-cloud', name: 'Mortuary Benefits' },
            { iconClass: 'fa fa-cloud', name: 'Community Development Fund' },
            { iconClass: 'fa fa-cloud', name: 'Patronage Fund' },
            { iconClass: 'fa fa-cloud', name: 'Yearly Incentives' },
            { iconClass: 'fa fa-cloud', name: 'Technical & Marketing Assistance' },
            { iconClass: 'fa fa-cloud', name: 'Seminars and Trainings' }
        ]
    }

    toggleInfoRequest = () => {
        this.setState((state,props) => {
            return { dropdownOpenInfoRequest: !state.dropdownOpenInfoRequest }
        })
    }

    send = (e) => {
        e.preventDefault()

        if (!this.state.infoRequest) {
            alert('Please select first some options on the Inquiry selection')
            return;
        }

        let formData = new FormData()
        for (let key in this.state) {
            formData.append(key, this.state[key])
        }

        fetch('https://api.sidc.coop/website/main/sendMembership.php', {
            method: 'POST', body: formData
        })
        .then(response => response)
        .then(data => {
            alert('Your Request has been sent. Please wait for us to contact you. Thank you')
            return data
        })
        .catch(error => {
            console.log(error)
            alert('Failed to send your request to our system. Please contact us via our email address or phone. Thank you.')
        })

        this.reset()
    }

    reset = () => {
        this.setState({
            dropdownOpenInfoRequest: false,
            infoRequest: null,
            firstName: '',
            middleName: '',
            lastName: '',
            addressStreet: '',
            addressState: '',
            addressCity: '',
            addressPostalCode: '',
            addressProvince: '',
            phoneNumber: '',
            addressCountry: '',
            emailAddress: '',
            note: ''
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    updateInfoRequest = (value) => {
        this.setState({ infoRequest: value })
    }

    render() {
        return (
            <BlockContainer padding={3} center={false}>
                <Container>
                    <Row>
                        <Col sm="12" md="5" className="pr-5">
                            <h3>Benefits you inquire for</h3>
                            <div className="text-justify">
                                <p>Cooperatives are voluntary organizations, open to all persons able to use their services and willing to accept the responsibilities of membership, without gender.</p>
                            </div>
                            <ListGroup flush>
                                {
                                    this.benefits.map((benefit, key) => {
                                        return (
                                            <ListGroupItem key={`${key}-${benefit.name}`}>
                                                <i className={`${benefit.iconClass} text-primary mr-2`}></i>
                                                { benefit.name }
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </Col>
                        <Col sm="12" md="7">
                            <h3 className="mb-3">Please Choose Options</h3>
                            <Form id="membership-inquiry" className="p-0" onSubmit={this.send}>
                                <Row form>
                                    <Col md="12">
                                        <FormGroup>
                                            <ButtonDropdown isOpen={this.state.dropdownOpenInfoRequest} toggle={this.toggleInfoRequest}>
                                            <DropdownToggle color="primary" caret>
                                                Info Request
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { this.updateInfoRequest('Call me please')}} active={this.state.infoRequest === 'Call me please'}>Call me please</DropdownItem>
                                                <DropdownItem onClick={() => { this.updateInfoRequest('Field Agent appointment')}} active={this.state.infoRequest === 'Field Agent appointment'}>Field Agent appointment</DropdownItem>
                                                <DropdownItem onClick={() => { this.updateInfoRequest('Help me finding a SIDC Branch')}} active={this.state.infoRequest === 'Help me finding a SIDC Branch'}>Help me finding a SIDC Branch</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md="12">
                                        <h3 className="mb-3">Please fill out the following informations</h3>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Input value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" id="firstName" placeholder="First Name" required />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Input value={this.state.middleName} onChange={this.handleChange} type="text" name="middleName" id="middleName" placeholder="Middle Name" required />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Input value={this.state.firstName} onChange={this.handleChange} type="text" name="lastName" id="lastName" placeholder="Last Name" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input value={this.state.addressStreet} onChange={this.handleChange} type="textarea" name="addressStreet" id="addressStreet" placeholder="Address Street / Lot / Block" row="2" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.addressState} onChange={this.handleChange} type="text" name="addressState" id="addressState" placeholder="Barangay" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.addressCity} onChange={this.handleChange} type="text" name="addressCity" id="addressCity" placeholder="City" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.addressPostalCode} onChange={this.handleChange} type="text" name="addressPostalCode" id="addressPostalCode" placeholder="Postal Code" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.addressProvince} onChange={this.handleChange} type="text" name="addressProvince" id="addressProvince" placeholder="Province" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.phoneNumber} onChange={this.handleChange} type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone" required />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Input value={this.state.addressCountry} onChange={this.handleChange} type="text" name="addressCountry" id="addressCountry" placeholder="Country" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input value={this.state.emailAddress} onChange={this.handleChange} type="email" name="emailAddress" id="emailAddress" placeholder="Email" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Input value={this.state.note} onChange={this.handleChange} type="textarea" name="note" id="note" placeholder="Some notes for us? Please insert here" rows="5" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button color="primary">Submit</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        )
    }

}

export default MembershipInquiry
